import React from "react"
import cn from "classnames"

const Box = ({ className, children }) => (
  <div className={cn("tw-p-6 tw-shadow tw-rounded-xl tw-bg-white", className)}>
    {children}
  </div>
)

export default Box
