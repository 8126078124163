import React from "react"
import Container from "~components/Container"
import { ReactComponent as InfoIcon } from "~images/svg/information.svg"
import MarkdownIt from "markdown-it"

const md = MarkdownIt()

const InfoSection = ({ message }) => (
  <section className="tw-bg-gray-200 tw-my-0 tw-py-8">
    <Container>
      <div className="tw-flex tw-justify-center tw-items-center">
        <InfoIcon className="tw-mr-4 tw-flex-shrink-0" width="48" />
        <div
          className="tw-max-w-md"
          dangerouslySetInnerHTML={{ __html: md.render(message) }}
        />
      </div>
    </Container>
  </section>
)

export default InfoSection
