import React from "react"
import cn from "classnames"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Box from "../Box"
import Button from "~components/Button"

const VisitCard = ({ visit, className, ...props }) => {
  const { summary, slug, title, thumbnail } = visit
  const image = getImage(thumbnail?.localFile)

  return (
    <Box
      className={cn(
        className,
        "media tw-transition-shadow tw-ease-in-out tw-duration-300 tw-p-6 tw-shadow hover:tw-shadow-xl"
      )}
      {...props}
    >
      {image ? (
        <GatsbyImage
          className="tw-mb-4 md:tw-mb-0"
          image={image}
          objectFit="contain"
          alt={thumbnail ? thumbnail.alternativeText : ""}
        />
      ) : (
        <img src={""} width="210" height="140" alt="" />
      )}
      <div>
        <h2
          dangerouslySetInnerHTML={{ __html: title }}
          className="h2 tw-mb-2"
        />
        <p className="tw-mb-3" dangerouslySetInnerHTML={{ __html: summary }} />

        <Link variant="contained" to={slug}>
          Lire la suite
        </Link>
      </div>
    </Box>
  )
}

export default VisitCard
