import React from "react"
import { graphql } from "gatsby"
import Container from "~components/Container"

import Layout from "../layouts"
import SEO from "~components/SEO"
import Hero from "~components/Hero"
import VisitCard from "~components/cards/VisitCard"
import BreadCrumb from "~components/Breadcrumb"
import { getImage, withArtDirection } from "gatsby-plugin-image"
import InfoSection from "~components/InfoSection"

const Visites = ({ pageContext, data }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  const { bannerImageMobile, bannerImageDesktop, visits } = data

  const images = withArtDirection(getImage(bannerImageMobile), [
    {
      media: "(min-width: 768px)",
      image: getImage(bannerImageDesktop),
    },
  ])

  return (
    <Layout>
      <SEO title="Visites" />

      <Hero background={images} />

      {data?.infoMessage?.active && (
        <InfoSection message={data?.infoMessage?.message} />
      )}

      <Container>
        <section className="tw-mt-8">
          <h1 className="h1 tw-text-center">Visites Guidées</h1>

          <p className="subtitle tw-text-center">
            Partez à la découverte de Paris et de l'Île-de-France à travers des
            visites programmées ou à la demande
          </p>

          <hr className="tw-mt-8" />

          <BreadCrumb crumbs={crumbs} crumbSeparator=" > " />

          {visits.edges.map(({ node }, index) => (
            <VisitCard key={index} visit={node} className="tw-mb-8" />
          ))}
        </section>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query VisitesPageQuery {
    infoMessage: strapiInfoMessage(active: { eq: true }) {
      active
      message
    }
    visits: allStrapiVisit {
      edges {
        node {
          id
          strapiId
          title
          slug
          summary
          thumbnail {
            alternativeText
            url
            height
            width
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  formats: [AUTO, WEBP, AVIF]
                  placeholder: BLURRED
                  width: 800
                )
              }
            }
          }
          visit_dates {
            date
          }
        }
      }
    }
    bannerImageMobile: file(
      relativePath: { eq: "banners/visit-page-banner-mobile.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
    bannerImageDesktop: file(
      relativePath: { eq: "banners/visit-page-banner.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
  }
`

export default Visites
